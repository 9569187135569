<template>
  <v-container>
    <v-row justify="center" align="center" class="mt-15">
      <v-img
        alt="404 Нет такой страницы"
        class="shrink mt-15"
        width="495"
        max-width="90%"
        contain
        src="../assets/404.png"
        transition="scale-transition"
      />
    </v-row>
    <v-row justify="center" align="center" class="mt-4">
      <v-btn
        text
        dark
        color="#af955c"
        @click="back"
      >
        <v-icon left dark>mdi-chevron-left</v-icon>
        Вернуться в кабинет
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
  methods: {
    back() {
      this.$router.push('/orders');
    }
  }
}
</script>